<template>
  <div class="font-manrope container">
    <div class="min-h-screen flex items-center justify-center">
      <slot />
    </div>
  </div>
</template>

<script setup>
</script>
